import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  private response: BehaviorSubject<{ msg: string; responseType: string }> =
    new BehaviorSubject<{ msg: string; responseType: string }>({msg: '', responseType: ''});

  setResponse(msg: string, responseType: string) {
    this.response.next({msg, responseType});
  }

  getResponse(): Observable<{ msg: string; responseType: string }> {
    return this.response.asObservable();
  }
}
