import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {APIS, USER_DETAILS, USER_CONSTANTS } from 'app/common/constants';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from './alert.service';
import { HttpService } from './http.service';
import { DEFAULT_PAGE } from 'app/common/constants';
import {ResponseService} from "./response.service";
import {SentryErrorHandler} from "./sentry-error-handler.service";

@Injectable({ providedIn: 'root' })
export class AuthUserService {
  UserDetails = USER_DETAILS
  authenticaed = new BehaviorSubject(null);
  constructor(private router: Router,
              private alertService: AlertService,
              private responseService: ResponseService,
              private sentryService:SentryErrorHandler,
              private httpService: HttpService) {
    this.errorHandler = this.errorHandler.bind(this);
  }

  /** User authenticated or not **/
  isAuthenticated(): boolean {
    if (localStorage.getItem(this.UserDetails.TOKEN) && localStorage.getItem(this.UserDetails.APPROVED) == 'true') {
      this.authenticaed.next(true);
      return true;
    }
    return false;
  }

  /** User logout **/
  logout() {
    const clearLocal = () => {
      localStorage.clear();
      this.router.navigate(['/']);
    };

    this.httpService.postData(APIS.LOGOUT, { platform: USER_CONSTANTS.PLATFORM }).subscribe(clearLocal, clearLocal);
  }

  /** Set user details **/
  setUserDetails(id, value) {
    localStorage.setItem(id, value);
    return;
  }

  /** Get user details **/
  getUserDetails(key) {
    return localStorage.getItem(key);
  }

  login(data) {
    this.httpService.postData(APIS.LOGIN_V2, data).subscribe((res) => {
      if (res?.user) {
        this.setUserDetails(USER_DETAILS.TOKEN, res?.token);
        this.setUserDetails(USER_DETAILS.ROLE, res?.user?.role_id)
        this.setUserDetails(USER_DETAILS.APPROVED, res?.user?.approved);
        this.setUserDetails(USER_DETAILS.LOCATION, res?.user?.primary_location);
        this.setUserDetails(USER_DETAILS.ID, res?.user?.id);
        this.setUserDetails(USER_DETAILS.Email, res?.user?.email);
        // localStorage.setItem('tryLogin','true');
        if (res?.user?.approved) {
          // console.log('--------->',res.user.role_id)
          this.router.navigateByUrl('/' + DEFAULT_PAGE);
          // let userEmail=res.user.email
          // if (userEmail=='desigual@28one.com') {
          //   this.router.navigateByUrl('/client');
          // } else {
          //   if (res.user.role_id == 1) {
          //     this.router.navigate(['/admin'])
          //   } else {
          //     if (res.user.role_id == 7) {
          //       this.router.navigate(['/superdry'])
          //     } else {
          //       this.router.navigate(['/user'])
          //     }
          //   }
          // }
          // this.router.navigate(['/dashboard'])
        }
      } else {
        this.sentryService.handleError(res?.msg);
        this.router.navigate(['/not-approved'])
        this.responseService.setResponse(res?.msg)
        this.alertService.errorToast(res?.msg)
      }
    }, this.errorHandler)
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }

  errorHandler(error) {
    // console.log(error)
    this.sentryService.handleError(error ?? 'Unable to connect to the server!');
    this.alertService.errorToast(error?.error?.msg ?? 'Unable to connect to the server!');
    this.responseService.setResponse(error?.error?.msg ?? 'Unable to connect to the server!');
    this.router.navigate(['/not-approved'])
  }
}
