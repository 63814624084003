import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { SentryConfig } from 'app/models/sentry-config.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {

  constructor() {

    const sentryConfiguration:SentryConfig | null = environment.sentry;

    if(!sentryConfiguration?.enable) { return;}

    const integrations = [];

    if (sentryConfiguration?.enableBrowserTracingIntegration) {
      integrations.push(Sentry.browserTracingIntegration());
    }
    if (sentryConfiguration?.enableReplayIntegration) {
      integrations.push(Sentry.replayIntegration());
    }

    Sentry.init({
      release: "CLIV-V1",
      environment: environment.ENV,
      dsn: "https://5027dfccf9ef8c632d03eb2fde42b4ad@o4506869003976704.ingest.us.sentry.io/4507101110206464",
      tunnel: `${environment.apiUrl}/v1/sentry/tunnel`,
      integrations: integrations,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      transportOptions:{
      headers:{'client-name':environment.clientName}
      }
    });
    if(Sentry){
            Sentry.setUser({
              id: localStorage.getItem("id"),
              name: localStorage.getItem("name"),
              role: localStorage.getItem("role"),
              email: localStorage.getItem("email"),
              location: localStorage.getItem("location"),
            });
      }

  }

  handleError(error: any) {
    console.error(error);

    if (environment.sentry) {
      Sentry.captureException(error.originalError || error);
    }
  }
}
