import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  private response:Subject<{msg:string}> = new Subject<{msg:string}>();

  setResponse(msg:string){
    this.response.next({msg});
  }
  getResponse(){
    return this.response.asObservable();
  }
}
